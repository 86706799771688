<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <v-row>
        <v-col cols="12" md="3">
          <v-row no-gutters>
            <v-col cols="12" class="text-start px-3">Data de cadastro</v-col>
            <v-col cols="6" class="px-1">
              <FormDatePicker
                v-model="filter.dataCadastroIni"
                label="Inicial"
              />
            </v-col>
            <v-col cols="6" class="px-1">
              <FormDatePicker
                v-model="filter.dataCadastroFim"
                :min="filter.dataCadastroIni"
                label="Final"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <v-row no-gutters>
            <v-col cols="12" class="text-start px-3">
              Data da última compra
            </v-col>
            <v-col cols="6" class="px-1">
              <FormDatePicker
                v-model="filter.dataUltimaCompraIni"
                label="Inicial"
              />
            </v-col>
            <v-col cols="6" class="px-1">
              <FormDatePicker
                v-model="filter.dataUltimaCompraFim"
                :min="filter.dataUltimaCompraIni"
                label="Final"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <v-row no-gutters>
            <v-col cols="12" class="text-start px-3">
              Data do último acesso
            </v-col>
            <v-col cols="6" class="px-1">
              <FormDatePicker
                v-model="filter.dataUltimoAcessoIni"
                label="Inicial"
              />
            </v-col>
            <v-col cols="6" class="px-1">
              <FormDatePicker
                v-model="filter.dataUltimoAcessoFim"
                :min="filter.dataUltimoAcessoIni"
                label="Final"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <v-row no-gutters>
            <v-col cols="12" class="text-start px-3">
              Data de nascimento
            </v-col>
            <v-col cols="6" class="px-1">
              <FormDatePicker
                v-model="filter.dataNascimentoIni"
                label="Inicial"
              />
            </v-col>
            <v-col cols="6" class="px-1">
              <FormDatePicker
                v-model="filter.dataNascimentoFim"
                :min="filter.dataNascimentoIni"
                label="Final"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row no-gutters>
            <FormSelect
              class="mb-n5"
              label="Segmentos"
              v-model="filter.segmentosSelecionados"
              multiple
              :options="segmentos"
            />
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-3 mt-n2">
                  far fa-question-circle
                </v-icon>
              </template>
              Agrupamento de clientes por comportamento de compra.
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row no-gutters>
            <FormSelect
              class="mb-n5"
              label="Lojas em que fez pedido"
              v-model="filter.fornecedoresSelecionados"
              multiple
              :options="fornecedores"
            />
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-3 mt-n2">
                  far fa-question-circle
                </v-icon>
              </template>
              Agrupamento de clientes por comportamento de compra.
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col cols="12" class="mt-n3">
          <v-row no-gutters>
            <FormCheckbox
              v-model="filter.carrinhoAberto"
              label="Apenas carrinho em aberto"
              class="mx-5"
            />
            <FormCheckbox
              v-model="filter.cadastroaprovado"
              label="Apenas cadastro aprovado"
              class="mx-5"
            />
            <FormCheckbox
              v-model="filter.enviarParaTodos"
              label="Enviar para todos clientes"
              class="mx-5"
            />
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mt-n2">
                  far fa-question-circle
                </v-icon>
              </template>
              Ao enviar para todos os clientes, será enviado a notificação para
              todos os clientes de acordo com o filtro informado acima.
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-end">
          <FormButton
            :disabled="filter.enviarParaTodos"
            type="primario"
            :loading="isLoading"
            @click="getClientes(true)"
          >
            Pesquisar
          </FormButton>
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="clientes.length > 0 || filter.enviarParaTodos"
    >
      <v-row>
        <v-col cols="12" md="6" class="pr-md-6" v-if="!filter.enviarParaTodos">
          <v-row no-gutters>
            <v-col cols="6" class="text-start"><h4>Pesquisa</h4></v-col>
            <v-col cols="6" class="text-end">
              <small class="mr-5"
                >{{ clientes.length }} clientes encontrados</small
              >
              <FormButton
                @click="addAllCliente(clientes)"
                type="success"
                v-if="clientes.length > 0"
              >
                <v-icon class="mr-3">fa-plus</v-icon>
                Adicionar Todos
              </FormButton>
            </v-col>
            <v-col cols="12" class="text-start">
              <div class="scroll-container mt-10" @scroll="onScroll">
                <v-simple-table @scroll="onScroll" v-if="clientes.length > 0">
                  <tbody>
                    <tr v-for="(cliente, i) in clientes" :key="i">
                      <td>
                        {{ cliente.nome }}
                        <v-tooltip right v-if="cliente.carrinhoaberto">
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              class="ml-3"
                              color="green"
                              size="15"
                            >
                              fa-cart-plus
                            </v-icon>
                          </template>
                          Tem carrinho aberto!
                        </v-tooltip>
                      </td>
                      <td>
                        <v-row no-gutters>
                          <v-col cols="12" class="text-no-wrap">
                            Data de cadastro:
                            {{ formataData(cliente.datacadastro) }}
                          </v-col>
                          <v-col cols="12" class="text-no-wrap">
                            Data de nascimento:
                            {{ formataData(cliente.datanascimento) }}
                          </v-col>
                        </v-row>
                      </td>
                      <td>
                        <v-row no-gutters>
                          <v-col cols="12" class="text-no-wrap">
                            Último acesso:
                            {{ formataData(cliente.ultimoacesso) }}
                          </v-col>
                          <v-col cols="12" class="text-no-wrap">
                            Última compra:
                            {{ formataData(cliente.ultimacompra) }}
                          </v-col>
                        </v-row>
                      </td>
                      <td>
                        <FormButton
                          @click="addCliente(cliente)"
                          icon
                          type="success"
                          v-if="
                            !clientesSelecionados.includes(cliente.idcliente)
                          "
                        >
                          <v-icon>fa-plus</v-icon>
                        </FormButton>
                        <FormButton
                          @click="removeCliente(cliente)"
                          icon
                          type="error"
                          v-else
                        >
                          <v-icon>fa-trash</v-icon>
                        </FormButton>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          :md="filter.enviarParaTodos ? '12' : '6'"
          class="pl-md-6"
          :class="!filter.enviarParaTodos ? 'border-left' : ''"
        >
          <v-row no-gutters>
            <v-col cols="12" md="7">
              <h4 class="text-start mb-5">Composição</h4>
              <FormField
                label="Título"
                placeholder="Título da notificação"
                v-model="form.messageTitle"
              />
              <FormText
                label="Texto"
                placeholder="Mensagem da notificação"
                :rows="8"
                v-model="form.messageText"
              />
              <FormField
                label="Link (Opcional)"
                placeholder="Link externo"
                v-model="form.messageLink"
                @blur="verifyMessageLink"
              />
            </v-col>
            <v-col cols="12" md="5">
              <v-img
                src="@/assets/preview-smartphone.png"
                width="320px"
                class="mx-auto"
              >
                <div class="white text-start preview-smartphone pa-2">
                  <h4 class="font-weight-bold text-no-wrap text-truncate">
                    {{ form.messageTitle }}
                  </h4>
                  <span>{{ form.messageText }}</span>
                </div>
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="mt-5"
      v-if="clientes.length > 0 || filter.enviarParaTodos"
    >
      <v-row>
        <v-col cols="12" class="text-start" v-if="listaClientes.length > 0">
          <v-simple-table>
            <tbody>
              <tr v-for="(cliente, i) in listaClientes" :key="i">
                <td>
                  {{ cliente.nome }}
                </td>
                <td class="text-end">
                  <FormButton @click="removeCliente(cliente)" icon type="error">
                    <v-icon>fa-trash</v-icon>
                  </FormButton>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col cols="12" class="text-end mt-5">
          <FormButton
            v-if="listaClientes.length > 0"
            type="error"
            class="mr-3"
            :loading="isLoading"
            @click="removeAllCliente()"
          >
            Remover todos os clientes
          </FormButton>
          <FormButton type="primario" :loading="isLoading" @click="onSubmit()">
            Enviar Mensagem
          </FormButton>
        </v-col>
      </v-row>
    </Panel>
    <Panel lineColor="var(--primary-color)" class="mt-5" v-else>
      <div class="pa-md-5">
        <v-icon
          size="64"
          color="primario"
          class="pa-5"
          style="border: 3px solid; border-radius: 50%;"
        >
          fa-users
        </v-icon>
        <p class="pt-3 primario--text font-weight-bold" style="font-size: 2em;">
          Pesquise os clientes...
        </p>
        <p class="primario--text font-weight-bold">
          Os resultados aparecerão aqui!
        </p>
      </div>
    </Panel>
  </div>
</template>

<script>
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormSelect from '@/components/form/FormSelect'
import FormField from '@/components/form/FormField'
import FormText from '@/components/form/FormText'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormDatePicker from '@/components/form/FormDatePicker'
import { mapGetters } from 'vuex'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { SEGMENTO_CLIENTE_LIST } from '@/store/actions/segmento'
import { FORNECEDOR_LOAD_ALL } from '@/store/actions/fornecedor'
import {
  NOTIFICACAO_PUSH_LOAD_CLIENTS,
  NOTIFICACAO_PUSH_SEND
} from '@/store/actions/notificacaoPush'
import moment from 'moment'

export default {
  name: 'NotificacaoPush',
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    },
    listaClientes() {
      const idSet = new Set()
      const clientes = []
      for (const cliente of this.listaDestinatarios || []) {
        if (!idSet.has(cliente.idcliente)) {
          idSet.add(cliente.idcliente)
          clientes.push(cliente)
        }
      }
      return clientes || []
    }
  },
  components: {
    Panel,
    FormButton,
    FormSelect,
    FormField,
    FormText,
    FormDatePicker,
    FormCheckbox,
    MarketingModules
  },
  data: () => ({
    isLoading: false,
    searchComplete: false,
    form: {
      messageLink: '',
      linkIsValid: false
    },
    filter: {},
    segmentos: [],
    fornecedores: [],
    clientes: [],
    clientesSelecionados: [],
    listaDestinatarios: [],
    modulosForm: [ModulesEnum.ORCAMENTO_APP, ModulesEnum.COMMERCE_APP],
    linkRegex: /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/
  }),
  methods: {
    verifyMessageLink() {
      if (this.form.messageLink === '') {
        this.form.linkIsValid = true
        return this.form.linkIsValid
      }
      if (!this.form.messageLink.startsWith('http')) {
        this.form.messageLink = 'http://' + this.form.messageLink
      }
      this.form.linkIsValid = this.linkRegex.test(this.form.messageLink)
      return this.form.linkIsValid
    },
    getSegmentos() {
      this.isLoading = true
      this.$store
        .dispatch(SEGMENTO_CLIENTE_LIST, {
          idfornecedor: this.getFornecedorId
        })
        .then(data => {
          this.segmentos = (data || []).map(v => {
            return { value: v.idsegmentocliente, text: v.nome }
          })
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    getFornecedores() {
      this.isLoading = true
      this.$store
        .dispatch(FORNECEDOR_LOAD_ALL)
        .then(data => {
          this.fornecedores = (data || []).map(v => {
            return { value: v.idfornecedor, text: v.nome }
          })
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    getClientes(clear) {
      if (clear) {
        this.clientes = []
      }
      if (this.isLoading) return
      const data = this.getParams()
      this.isLoading = true
      this.$store
        .dispatch(NOTIFICACAO_PUSH_LOAD_CLIENTS, data)
        .then(data => {
          this.clientes = this.clientes.concat(data)
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    onScroll(event) {
      const scrollContainer = event.target
      if (
        scrollContainer.scrollTop + scrollContainer.clientHeight >=
        scrollContainer.scrollHeight - 10
      ) {
        this.getClientes(false)
      }
    },
    getParams() {
      return {
        idfornecedor: this.getFornecedorId,
        cadastroStart: this.queryDate(true, this.filter.dataCadastroIni),
        cadastroEnd: this.queryDate(false, this.filter.dataCadastroFim),
        compraStart: this.queryDate(true, this.filter.dataUltimaCompraIni),
        compraEnd: this.queryDate(false, this.filter.dataUltimaCompraFim),
        acessoStart: this.queryDate(true, this.filter.dataUltimoAcessoIni),
        acessoEnd: this.queryDate(false, this.filter.dataUltimoAcessoFim),
        nascimentoStart: this.queryDate(true, this.filter.dataNascimentoIni),
        nascimentoEnd: this.queryDate(false, this.filter.dataNascimentoFim),
        carrinhoAberto: this.filter.carrinhoAberto || '',
        cadastroAprovado: this.filter.cadastroaprovado || '',
        segmentosSelecionados: this.filter.segmentosSelecionados,
        fornecedoresSelecionados: this.filter.fornecedoresSelecionados,
        limit: 100,
        offset: this.clientes.length || 0
      }
    },
    onSubmit() {
      this.verifyMessageLink()
      if (!this.form.messageText || !this.form.messageTitle) {
        this.$vueOnToast.pop('error', 'O título e mensagem são obrigatórios!')
        return
      }
      if (this.listaDestinatarios.length == 0 && !this.filter.enviarParaTodos) {
        this.$vueOnToast.pop(
          'error',
          'Selecione ao menos um cliente para enviar notificação.'
        )
        return
      }
      if (!this.form.linkIsValid) {
        this.$vueOnToast.pop(
          'error',
          'O link inserido é inválido! Confira também se a página está acessível.'
        )
        return
      }
      let tokens = []
      let searchParams = null
      this.$vueOnToast.pop(
        'info',
        'Iniciando o processo de envio de notificações...',
        1000
      )
      if (!this.filter.enviarParaTodos) {
        this.listaDestinatarios.map(dest => {
          dest.tokens.map(token => {
            tokens.push(token)
          })
        })
      } else {
        searchParams = this.getParams()
      }
      const data = {
        tokens: tokens,
        titulo: this.form.messageTitle,
        mensagem: this.form.messageText,
        link: this.form.messageLink,
        dadosBuscaClientes: searchParams
      }
      this.isLoading = true
      this.$store
        .dispatch(NOTIFICACAO_PUSH_SEND, data)
        .then(response => {
          setTimeout(() => {
            if (response.ok) {
              this.isLoading = false
              this.$vueOnToast.pop(
                'success',
                'Notificação enviada com sucesso.'
              )
              this.form = {}
              this.filter = {}
              this.clientesSelecionados = []
              this.listaDestinatarios = []
              this.clientes = []
            } else {
              this.isLoading = false
              this.$vueOnToast.pop('error', 'Falha ao enviar notificações!')
            }
          }, 1100)
        })
        .catch(() => {
          this.$vueOnToast.pop('error', 'Falha ao enviar notificações!')
          this.isLoading = false
        })
    },
    formataData(data) {
      const date = data ? moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
      return date
    },
    queryDate(start, date) {
      if (start && date) {
        return moment(date, 'YYYY-MM-DD')
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss')
      } else if (!start && date) {
        return moment(date, 'YYYY-MM-DD')
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss')
      }
      return ''
    },
    addCliente(cliente) {
      this.clientesSelecionados.push(cliente.idcliente)
      this.listaDestinatarios.push(cliente)
    },
    removeCliente(cliente) {
      this.clientesSelecionados = this.clientesSelecionados.filter(
        c => c !== cliente.idcliente
      )
      this.listaDestinatarios = this.listaDestinatarios.filter(
        c => c.idcliente !== cliente.idcliente
      )
    },
    addAllCliente(clientes) {
      this.clientesSelecionados = []
      this.listaDestinatarios = []
      this.clientesSelecionados = clientes.map(c => c.idcliente)
      this.listaDestinatarios = clientes
    },
    removeAllCliente() {
      this.clientesSelecionados = []
      this.listaDestinatarios = []
    }
  },
  watch: {
    'filter.enviarParaTodos'(newValue) {
      if (newValue) {
        this.removeAllCliente()
      }
    }
  },
  created() {
    this.getSegmentos()
    this.getFornecedores()
  }
}
</script>

<style scoped>
.static-texts-container {
  max-height: 450px;
  overflow-y: auto;
}
.preview-smartphone {
  position: absolute;
  width: 80%;
  min-height: 15%;
  top: 50%;
  left: 10%;
}
.border-left {
  border-left: 1px solid #ccc;
}
.scroll-container {
  height: 600px;
  overflow-y: auto;
}
</style>
